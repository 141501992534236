import React from 'react'
import {Stack} from '../common'
import {ZHelmet} from '../components/ZHelmet'
import {Clock} from '../common/icons/ClockIcon'
import {Money} from '../common/icons/MoneyIcon'
import {Repayment} from '../common/icons/RepaymentIcon'
import {Bell} from '../common/icons/BellIcon'
import {EligibilityCriteria} from '../components/CityEligibilityCriteria'
import {CityProducts} from '../components/CityProducts'
import {CityWhyUs} from '../components/CityWhyUs'
import {CityBeforeApplyLoan} from '../components/CityBeforeApplyLoan'
import {CityLanding} from '../components/CityLanding'
import {CityList} from '../components/CityList'
import { CityMobileLayout } from '../components/CityMobileLayout'

const Features = [
  {
    icon: <Clock />,
    title: 'Apply Instantly',
    content:
      'Applying for a Personal Loan is easy with Zavron. Just fill the form and we’ll direct you forward.',
  },
  {
    icon: <Money />,
    title: 'High Disbursal Amount',
    content:
      'If you fit into the eligibility criteria, then you can apply for a loan of upto 10 lacs.',
  },
  {
    icon: <Repayment />,
    title: 'Easy Repayment',
    content:
      'Our flexible repayment options and competitive interest rates help you repay loans within a period of 60 months.',
  },
  {
    icon: <Bell />,
    title: 'Easy Documentation',
    content:
      'We assist you with Personal Loans with easy and hassle-free documentations.',
  },
]

const Easy_Steps = [
  {
    title: 'Sign Up your accounts',
    content: [
      `You have to fill minimal details about you and your professoin to create an account.`,
    ],
  },
  {
    title: 'Fill Form Details',
    content: [
      `Filling form is very easy just keep typing the details as guided by App`,
    ],
  },
  {
    title: 'Get Instant Personal Loan',
    content: [
      `Once you finished you may get an instant personal loan as per your eligibility.`,
    ],
  },
]

const befor_Apply = [
  {
    title: '',
    content: ['Copy of PAN'],
  },
  {
    title: '',
    content: [
      'Last 4 Months Salary Account s Bank Statement. Please that your statement should be bank generated and in PDF format only.',
    ],
  },
  {
    title: '',
    content: ['Aadhar / Driving Licence / Passport / Voter ID (Any one)'],
  },
]

export default function Personal_loan_in_Mysore() {
  return (
    <CityMobileLayout>
      <ZHelmet
        title="Personal Loan - Mysore | ZinCash| Loan App | Download Now"
        description="Apply now to get instant personal loan in Mysore up to 2 lakh from ZinCash quick loan app by Zavron Finserv download now best loan App for urgent loans"
        keywords="Personal Loan App, Instant Personal Loan App, Mobile Loan App, Quick Loan App, ZinCash Loan App, Download Personal Loan App, Lending App, Get Loan App, Best Loan App,"
      />
      <Stack space="var(--s3)">
        <CityLanding
          title="Personal Loan in Mysore"
          city_image={'https://res.cloudinary.com/dhoiqmk4x/image/upload/v1631687249/Website/City/personal-loan-in-mysore_oe5bgw.png'}
          sub_title="Hege iddiya Mysore!"
          description={[
            'Mysore is also known as Mysore is the second-largest software exporter in Karnataka. The city wilI soon have a sky wheel which will give a panoramic view of the city within a 20km radius. The city boasts of a population that is wal king towards consistent growth and requires financial services.',
            'ZinCash  loan app is there to  help them get instant personal  loans to meet emergency needs like travel, education, electronic gadgets, wedding, and home renovation. Download the app for faster, hassle-free loan approval.',
          ]}
        />
        <CityProducts easy_steps={Easy_Steps} />

        <CityWhyUs features={Features} />

        <EligibilityCriteria />

        <CityBeforeApplyLoan
          subtitle="We recommend you to keep following documents handy before you apply for an Instant Persolan loan through ZinCash"
          befor_Apply={befor_Apply}
        />

        <CityList />
      </Stack>
    </CityMobileLayout>
  )
}
